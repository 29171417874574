
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import {fullName, fileSize} from '@/utils/dataExtractors'
  import {stringSort, shortDate, dateTime} from '@/utils/helper'
  import {modelTranslation} from '@/utils/apiDataHelper'
  import HtmlElement from "@/components/shared/form/elements/html/HtmlElement.vue"
  import { confirm } from '@/utils/interactionModals'

  interface Props {
    [key:string]: any
  }

  export default defineComponent({
    components: {
      HtmlElement,
    },
    props: {
      mailData: {
        type: Object,
        required: true
      },
    },
    setup(props: Props, { emit } ) {
      const root = getCurrentInstance().proxy
      const cancelAction = async () => {
        const confirmation = await confirm(root, 'Alle Eingaben werden verworfen...')
        if (confirmation) emit('cancel')
      }

      const attTotal = props.mailData.fmds.reduce((s,f)=>{return s+=f.length},0)

      const okAction = () => { emit('ok', props.mailData) }

      return {
        dateTime,
        cancelAction,
        okAction,
        modelTranslation,
        fileSize,
        attTotal
      }
    }
  })


  import { defineComponent, computed, getCurrentInstance } from "vue"
  import moment from 'moment'

  export default defineComponent({
    name: 'TicketMessage',
    props: {
      message: {
        type: Object,
        default: () => ({ payload: '' })
      },
      editEnabled: {
        type: Boolean,
        default: true
      }
    },
    setup(props) {
      const root = getCurrentInstance().proxy
      const message = props.message
      const createdAt = computed(() => moment(message.createdAt).locale('de').format('lll'))

      return {
        createdAt
      }
    }
  })


  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import TitleActions from "@/components/shared/TitleActions.vue";
  import LoadingOverlay from "@/components/shared/LoadingOverlay.vue";
  import { Ticket } from "@/models/tickets";
  import { Subscription } from "@/models/subscriptions";
  import { SingleResourceDoc } from '@/models/jsonapi'
  import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
  import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from "@/models/jsonapi";
  import {apiDataMorpher, extractRelationshipIds, modelTranslation} from "@/utils/apiDataHelper";
  import { confirm, prompt } from '@/utils/interactionModals'
  import { resourceUrl } from "@/utils/dataExtractors";
  import TicketMailEdit from "@/components/shared/TicketMailEdit.vue"
  import TicketMessage from "@/components/shared/TicketMessage.vue"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import Tasks from "@/components/shared/Tasks.vue"

  interface Props {
    id: string,
    action?: string
  }

  interface Sub {
    active: boolean,
    displayType: string,
    ids: string[],
  }

  export default defineComponent({
    components: {
      TitleActions,
      TicketMessage,
      LoadingOverlay,
      FileMetaData,
      TicketMailEdit,
      Tasks
    },
    props: {
      id: {
        type: String,
        required: true
      },
      action: {
        type: String
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const ticket = computed<ResourceObject<string, Ticket>>(() => root.$store.state.tickets.element)
      watch(ticket, (newValue) => {
        const subIds = extractRelationshipIds({ data: [newValue] }, 'subscriptions')
        const subsQuery = {
          'filter[id]': subIds.join(','), 'page[number]': 1, 'page[size]': subIds.length, include: 'proto_user'
        }
        root.$store.dispatch('subscriptions/load', subsQuery)
      })
      const subscriptions = computed(() => root.$store.state.subscriptions.data)
      const mailEditModal = ref()
      const mailData = ref<{[key: string]: any}>({})
      const editId = ref('')
      const editFilename = ref('')
      const showLoading = ref(false)
      const ticketMessage = ref('')
      const ticketSubs = computed<Sub[]>(() => {
        if (!subscriptions.value) return []
        const groupedSubs = subscriptions.value.reduce(subGrouper,{})
        return Object.keys(groupedSubs).map((type) => {
          const subs = groupedSubs[type] as ResourceObject<string, Subscription>[]
          const active = (subs.map((s)=>s.attributes?.active).indexOf(true) > -1)
          const ids = (subs.map((s)=>s.id)) as string[]
          return {
            active: active,
            displayType: displayType(type),
            ids: ids,
          }
        })
      })

      const refreshData = () => {
        root.$store.dispatch('tickets/get', { id: props.id, queryParams: { include: 'file_meta_datas' } })
        root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include: 'action_type' } )
      }

      refreshData()

      const subGrouper = (res, sub) => {
        const type = sub.attributes?.proto_user?.attributes?._type
        if (!res[type]) {res[type] = []}
        res[type].push(sub)
        return res
      }

      const ticketMessages = computed(() => {
        if (!ticket.value) return []
        const messages = ticket.value.attributes?.messages as any[]
        return messages.sort((a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any))
      })

      // const performTicketAction = async () => {
      //   console.log('pa: ',props.action)
      //   if (props.action === 'view') {
      //     await createNewTicketMessage()
      //   } else if (props.action === 'archive') {
      //     await archiveTicket()
      //   }
      //   emit('done', true)
      // }

      const createNewTicketMessage = async () => {
        if (ticketMessage.value.length > 0) {
          // Create the new message
          const data = {attributes: {messages: [{ payload: ticketMessage.value }]}}
          showLoading.value = true
          try {
            await root.$store.dispatch('tickets/edit', { id: props.id, body: data })
          } finally {
            ticketMessage.value = ''
            refreshData()
            showLoading.value = false
          }
        }
      }

      const displayType = (type: string) => {
        const out = {
          'Seller': 'Vertrieb',
          'Assistant': 'Vertrieb',
          'Internal': 'Innendienst',
          'Provider': 'Agentur',
        }[type]
        return out ? out : 'Unbekannt'
      }

      const getType = (sub: any) => displayType(sub.attributes.proto_user.attributes['_type'])

      const subTypeClicked = (sub: Sub) => {
        sub.ids.forEach(async (id) => {
          const updateData = { attributes: { active: !sub.active } }
          showLoading.value = true
          try {
            await root.$store.dispatch('subscriptions/edit', { id: id, body: updateData })
          } finally {
            showLoading.value = false
            sub.active = !sub.active
          }
        })
      }

      const updateTicket = async (data: any) => {
        showLoading.value = true
        try {
          await root.$store.dispatch('tickets/edit', { id: props.id, body: data })
        }  finally {
          refreshData()
          showLoading.value = false
        }
      }

      const messageCountInfo = computed(() => {
        const c = ticketMessages.value.length
        const w = c === 1 ? 'Nachricht' : 'Nachrichten'
        return `(${c} ${w})`
      })

      const updateMessage = (message: {[key: string]: any}) => {
        const data = {
          id: message.id,
          payload: message.payload,
          mailIncluded: message.mailIncluded,
        }
        showLoading.value = true
        root.$store.dispatch('tickets/edit', { id: props.id, body: { attributes: { messages: [data] } } }).finally(() => {
          showLoading.value = false
        })
      }

      const getMailData = async () => {
        showLoading.value = true
        await root.$store.dispatch('tickets/get_ticket_mail_data', props.id)
        mailData.value = root.$store.state.tickets.ticketMailData
        mailEditModal.value.show()
        showLoading.value = false
      }

      const markRead = () => {
        root.$store.dispatch('tickets/edit', {id: props.id, body: {usecase: 'mark_read'}})
      }

      const sendMailData = async (mailData: any) => {
        try {
          const {fmds, messages, ...rest} = mailData
          showLoading.value = true
          await root.$store.dispatch('tickets/deliver_ticket_mail_data', { id: props.id, data: {mailData: rest} })
          mailEditModal.value.hide()
          refreshData()
        } catch(e) {
          console.log('Ticketmail Error: ', e)
        } finally {
          showLoading.value = false
        }
      }

      const titleActions = ref([
        {
          title: '"Dringend" Umschalten',
          icon: 'exclamation-triangle',
          tooltip: 'Beim Ticket und allen vorhandenen Tasks wird "Dringend" umgestellt',
          onClick: async () => {
            if (typeof(ticket.value?.attributes?.priority) === 'boolean') {
              const data = {attributes: {priority: !ticket.value.attributes.priority}}
              await root.$store.dispatch('tickets/edit', { id: props.id, body: data })
              refreshData()
            }
          },
        },
        {
          title: 'Mail an Kunde',
          icon: 'envelope',
          tooltip: 'Mail an Kunden',
          onClick: getMailData,
        },
        {
          title: 'Gelesen!',
          icon: ['fad', 'check-circle'],
          tooltip: 'Als gelesen markieren (Meine Benachrichtigung löschen)',
          onClick: markRead,
        },
        // {
        //   title: 'Archivieren',
        //   icon: 'archive',
        //   onClick: () => alert('Archivieren')
        // },
      ])


      return {
        ticket,
        ticketSubs,
        modelTranslation,
        createNewTicketMessage,
        titleActions,
        ticketMessages,
        ticketMessage,
        getType,
        subTypeClicked,
        showLoading,
        messageCountInfo,
        resourceUrl,
        refreshData,
        updateTicket,
        updateMessage,
        mailEditModal,
        sendMailData,
        mailData

      }
    }
  })
